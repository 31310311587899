<template>
  <BaseListPage locale-section="pages.runeTypes" route="runeType">
    <RuneTypesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "RuneTypesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    RuneTypesList: () => import("./RuneTypesList")
  }
};
</script>
